import * as Yup from "yup";
// import { is18YearsOld, localeToUSADateString } from "../../../utils/is18YearsOld";

export const contactFormValidation = () => {
    return (
        Yup.object().shape({
            name: Yup.string()
                .required("Nome é requerido")
                .matches(/[a-z]\s[a-z]/i, "Nome Incompleto"),
            email: Yup
                .string()
                .email("Email não é válido")
                .required("Email é requerido"),
            phoneNumber: Yup
                .string().nullable()
                .required("Telefone é requerido")
                .trim()
                .length(19, "Número de Telefone inválido"),
            topic: Yup.string()
                .required("Assunto é requerido"),
            message: Yup.string()
                .required("Mensagem é requerido"),
            // language: Yup
            //     .required("Escolha uma opção"),

        })
    )
}