import * as React from "react"
import {
  ChakraProvider,
  theme,
  ColorModeScript,
} from "@chakra-ui/react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import HomePage from "./pages/homePage/homepage"

export const App = () => (
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={"dark"} />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />

      </Routes></BrowserRouter>
  </ChakraProvider>
)
