"use client";

import {
  Box,
  Flex,
  // HStack,
  Text,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Link,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  Icon,
  Collapse,
  // useBreakpointValue,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  AddIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";

import { Link as Smooth } from "react-scroll";

import logo from "../../../assets/imgs/blakclogo.png";

// adicionar label id href ?

// interface Props {
//   children: React.ReactNode;
// }

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "HOME",
    href: "#",
  },
  {
    label: "SOBRE NÓS",
    href: "aboutUs",
  },
  {
    label: "EQUIPE",
    href: "team",
  },
];

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        bg={"white"}
        color={useColorModeValue("gray.600", "white")}
        minH={"10vh"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            color={"black"}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Box
            color={"black"}
            ml={["0%", "20%"]}
            display={"flex"}
            justifyItems={"center"}
            alignItems={"center"}
          >
            <Image src={logo} maxW={"70px"} />
            <Flex
              display={["none", "flex"]}
              fontSize={["lg", "lg", "lg", "lg", "2xl", "2xl"]}
            >
              <Text ml={"10%"} color={"#0d1f2d"} w={"100%"}>
                RAZZINES
              </Text>
              <Text ml={"5%"} color={"#0d1f2d"} w={"100%"}>
                &
              </Text>
              <Text ml={"5%"} color={"#0d1f2d"} w={"100%"}>
                AVILA
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Box display={["none", "flex"]}>
          <Box marginRight={"10px"} cursor={"pointer"} mt={"5px"}>
            <motion.div
              className="container"
              whileHover={{ scale: 1.2, rotate: 360 }}
              whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
            >
              <Link
                href="https://www.facebook.com/RazzineseAvila/"
                cursor={"pointer"}
                isExternal
              >
                <Icon
                  color="#0d1f2d"
                  boxSize={["10px", "30px"]}
                  as={FaFacebook}
                />
              </Link>
            </motion.div>
          </Box>
          <Box mt={"5px"}>
            <motion.div
              className="container"
              whileHover={{ scale: 1.2, rotate: 360 }}
              whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
            >
              <Link
                href="https://www.instagram.com/razzineseavila.adv/"
                isExternal
              >
                <Icon
                  color="#0d1f2d"
                  boxSize={["10px", "30px"]}
                  as={FaInstagram}
                />
              </Link>
            </motion.div>
          </Box>
          <Box marginLeft={"10px"} mt={"5px"}>
            <motion.div
              className="container"
              whileHover={{ scale: 1.2, rotate: 360 }}
              whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
            >
              <Link href="https://wa.me/5541998736363" isExternal>
                <Icon
                  color="#0d1f2d"
                  boxSize={["10px", "30px"]}
                  as={FaWhatsapp}
                />
              </Link>
            </motion.div>
          </Box>
        </Box>

        <Flex display={{ base: "none", md: "flex" }} mr={10}>
          <DesktopNav />
        </Flex>
        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Link href="https://wa.me/5541998736363"  _hover={{ textDecoration: "none" }}textDecoration={"none"} isExternal>
            <Button
              display={["none", "flex", "none", "flex", "flex", "flex"]}
              variant={"solid"}
              bg={"#40798C"}
              _hover={{ bg: "#70A9A1" }}
              size={"md"}
              mr={4}
              color={"white"}
              w={"100%"}
              fontSize={"sm"}
              leftIcon={<AddIcon />}
            >
              Entrar em Contato
            </Button>
          </Link>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = "#0d1f2d";
  const linkHoverColor = useColorModeValue("gray.800", "Black");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                w={"auto"}
                p={2}
                fontSize={["md", "md", "md", "md", "xl", "xl"]}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  backgroundColor: linkHoverColor,
                  color: "white",
                }}
                borderRadius={"15px"}
              >
                <Smooth
                  activeClass="active"
                  to={navItem.href ?? "#"}
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  {navItem.label}
                </Smooth>
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
      <Box display={["flex", "none"]}>
        <Box marginRight={"10px"} cursor={"pointer"} mt={"5px"}>
          <motion.div
            className="container"
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
          >
            <Link
              href="https://www.facebook.com/RazzineseAvila/"
              cursor={"pointer"}
              isExternal
            >
              <Icon color="black" boxSize={["30px", "30px"]} as={FaFacebook} />
            </Link>
          </motion.div>
        </Box>
        <Box mt={"5px"}>
          <motion.div
            className="container"
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
          >
            <Link
              href="https://www.instagram.com/razzineseavila.adv/"
              isExternal
            >
              <Icon color="black" boxSize={["30px", "30px"]} as={FaInstagram} />
            </Link>
          </motion.div>
        </Box>
        <Box marginLeft={"10px"} mt={"5px"}>
          <motion.div
            className="container"
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
          >
            <Link href="https://wa.me/5541998736363" isExternal>
              <Icon color="black" boxSize={["30px", "30px"]} as={FaWhatsapp} />
            </Link>
          </motion.div>
        </Box>
      </Box>
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Smooth}
        to={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2}>
                <Smooth
                  activeClass="active"
                  to={child.href ?? "#"}
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  {child.label}
                </Smooth>
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

//todo finish navbar using https://chakra-templates.dev/navigation/navbar/

//old

// const NavLink = (props: Props) => {
//   const { children } = props

//   return (
//     <Box
//       // as="a"
//       px={2}
//       py={1}
//       rounded={'md'}
//       _hover={{
//         textDecoration: 'none',
//         bg: "#70A9A1",
//         cursor: "pointer",
//       }}
//       // href={'#'}
//       color={"#0d1f2d"}

//     >
//       <Smooth activeClass="active" to="EQUIPE" spy={true} smooth={true} duration={1000} >
//         {children}
//       </Smooth>
//     </Box>
//   )
// }

// const Links = ['HOME', 'SOBRE NÓS', 'EQUIPE']

// <>
//   <Box bg={"#F8E5EE"} px={4}>
//     <Flex h={"10vh"} alignItems={'center'} justifyContent={'space-between'}>
//       <IconButton
//         size={'md'}
//         icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
//         aria-label={'Open Menu'}
//         display={{ md: 'none' }}
//         onClick={isOpen ? onClose : onOpen}
//       />
//       <HStack spacing={8} alignItems={'center'} w={"120%"}>
//         <Box color={"black"} ml={"20%"} display={"flex"} justifyItems={"center"} alignItems={"center"}>
//           <Image src={logo} maxW={"70px"} />
//           <Flex>
//             <Text ml={"10%"} fontSize='2xl' color={"#0d1f2d"} w={"100%"}>
//               RAZZINES
//             </Text>
//             <Text ml={"5%"} fontSize='2xl' color={"#0d1f2d"} w={"100%"}>
//               &
//             </Text>
//             <Text ml={"5%"} fontSize='2xl' color={"#0d1f2d"} w={"100%"}>
//               AVILA
//             </Text>
//           </Flex>
//         </Box>

//       </HStack>

//       <Flex alignItems={'center'} w={"85%"} mr={"10%"}>
//         <Box>
//           <Flex justifyContent={"Center"} mr={"10px"}>

//           </Flex>
//         </Box>
//         <HStack w={"100%"} as={'nav'} minW={"auto"} minH={"auto"} spacing={4} mr={"10px"} color={"#E4Ba51"} fontSize={"20px"} display={{ base: 'none', md: 'flex' }}>
//           {Links.map((link) => (
//             <NavLink key={link}>{link}</NavLink>
//           ))}
//         </HStack>
//         <Button
//           variant={'solid'}
//           bg={"#40798C"}
//           _hover={{ bg: "#70A9A1" }}
//           size={'md'}
//           mr={4}
//           w={"40%"}
//           fontSize={"sm"}
//           leftIcon={<AddIcon />}
//         >
//           Entrar em Contato
//         </Button>

//       </Flex>
//     </Flex>

//     {NAV_ITEMS.map((navItem) => (
//       <Box key={navItem.label}>
//         <Popover trigger={'hover'} placement={'bottom-start'}>
//           <PopoverTrigger>
//             <Link
//               p={2}
//               href={navItem.href ?? '#'}
//               fontSize={'sm'}
//               fontWeight={500}
//               color={linkColor}
//               _hover={{
//                 textDecoration: 'none',
//                 color: linkHoverColor,
//               }}>
//               {navItem.label}
//             </Link>
//           </PopoverTrigger>

//           {navItem.children && (
//             <PopoverContent
//               border={0}
//               boxShadow={'xl'}
//               bg={popoverContentBgColor}
//               p={4}
//               rounded={'xl'}
//               minW={'sm'}>
//               <Stack>
//                 {navItem.children.map((child) => (
//                   <DesktopSubNav key={child.label} {...child} />
//                 ))}
//               </Stack>
//             </PopoverContent>
//           )}
//         </Popover>
//       </Box>
//     ))}
//   </Box>
// </>
