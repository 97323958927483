import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import '@fontsource/prompt';


export const DarkWrapper = styled(Box)`
    background: #1D201F;
    background-size: cover;
    background-position: right center, center;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding-bottom: 0px;
    color: #fff;
    // overflow: hidden;
    boxShadow: 23px 23px 55px #807e7e,-23px -23px 55px #ffffff;
    
    & * {
        font-weight: 500;
        font-family: 'Prompt', sans-serif;
    }
`;
//  import '@fontsource/advent-pro' font-family:"Advent Pro", sans-serif; maybe change the font later

//    background-image: linear-gradient(to top right, rgb(219, 234, 254), rgb(147, 197, 253), rgb(59, 130, 246)); maybe change