import React from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
    Button,
    FormControl,
    FormControlProps,
    FormErrorMessage,
    Input,
    InputProps,
    // useToast,
    Text,
    Box,
    FormLabel,
    // InputGroup,
    Textarea,
    // Select,
} from "@chakra-ui/react";
// import Userfront from "@userfront/core";
// import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
// import { useQuery } from "../../../hooks/routerHooks";
import { contactFormValidation } from "./validation";
// import { Checkbox } from "./styled";
// import { UserfrontSignupResponse, UserRegisterPayload } from "./types";
// import { register } from "./service";

import "../Form.scss";
// import { localeToUSADateString } from "../../../utils/is18YearsOld";
import  {  PatternFormat } from "react-number-format";
import Swal from 'sweetalert2'

// number format NumberFormat, {NumericFormat,}
// import { Trans, useTranslation } from "react-i18next";

// Userfront.init(process.env.REACT_APP_USERFRONT_TENANT);

// const REFERRAL_QUERYSTRING = "ref";

export const formControlProps: FormControlProps = {
    mb: 12 / 4,
};
export const inputProps: InputProps = {
    bgColor: "white",
    color: "gray.900",
    _focus: { outline: 0 },
    _hover: { outline: 0, boxShadow: "lg" },
    fontSize: ["small", "sm", "lg"],
};

const ContactForm: React.FC = () => {
    // const query = useQuery();
    // const { t } = useTranslation(["sign", "toasts"]);
    // const toast = useToast();
    // const [passwordVisibility, setpasswordVisibility] = React.useState(false)
    // const [confirmpasswordVisibility, setconfirmpasswordVisibility] = React.useState(false)
    
    const initialValues = {
        name: "",
        email: "",
        phoneNumber: "",
        topic: "",
        message: "",
    };

    const onSubmit = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        Swal.fire({
            title: 'Sucesso!',
            text: 'Mensagem enviada',
            icon: 'success',
            timer: 2000,          })
 
    }
    return <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={contactFormValidation()}
    >
        {(props) => (
            <Box w={"100%"} paddingLeft={["5vh","10vh"]} paddingRight={["5vh","10vh"]} paddingBottom={"0vh"} paddingTop={"3vh"}>
                <Form style={{ textAlign: "left", fontSize: "10px", display: "contents", justifyContent: "center", alignItems: "center" }}>
                    <Box display={"flex"} flexDir={["column","row"]} justifyContent={"center"} mt={1}>
                        <Box mr={3} w={["100%","80%"]}>
                            <Field name="name">
                                {({ field, form }: any) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name} {...formControlProps} w={"100%"}>
                                        <FormLabel
                                            htmlFor="name"
                                            color={"#4365BE"}
                                            fontSize={["xs", "16px"]}
                                        >
                                            Nome Completo
                                        </FormLabel>
                                        <Input {...field} type="text" id="name" {...inputProps} className="input-field" h={"70px"} />
                                        <FormErrorMessage fontSize={["10px", "16px"]} noOfLines={[1, 2]} maxW={200} alignItems={"start"}>{form.errors.name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="email">
                                {({ field, form }: any) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email} {...formControlProps} w={"100%"}>
                                        <FormLabel
                                            htmlFor="email"
                                            color={"#4365BE"}
                                            fontSize={["xs", "16px"]}
                                        >
                                            Email
                                        </FormLabel>
                                        <Input {...field} type="email" id="email"{...inputProps} className="input-field" h={"70px"} />
                                        <FormErrorMessage fontSize={["10px", "16px"]} noOfLines={[1, 2]} maxW={200} alignItems={"start"}>{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Box>
                        <Box ml={["0%",3]} w={["100%","80%"]}>
                            <Field name="phoneNumber">
                                {({ field, form }: any) => (
                                    <FormControl
                                        isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}
                                        {...formControlProps} width={["100%", "100%", "100%", "100%"]}>
                                        <FormLabel
                                            htmlFor="phoneNumber"
                                            color={"#4365BE"}
                                            fontSize={["xs", "16px"]}
                                        >
                                            Número de telefone
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            type="tel"
                                            id="phoneNumber"
                                            {...inputProps}
                                            className="input-field"
                                            as={PatternFormat}
                                            format="+## (##) #####-####"
                                            h={"70px"}
                                        />
                                        <FormErrorMessage fontSize={["10px", "16px"]} noOfLines={[1, 2]} maxW={200} alignItems={"start"}>{form.errors.phoneNumber}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="topic">
                                {({ field, form }: any) => (
                                    <FormControl isInvalid={form.errors.topic && form.touched.topic} {...formControlProps} width={"100%"}>
                                        <FormLabel
                                            htmlFor="topic"
                                            color={"#4365BE"}
                                            fontSize={["xs", "16px"]}
                                        >
                                            Assunto
                                        </FormLabel>
                                        <Input {...field} type="text" id="topic" {...inputProps} className="input-field" h={"70px"} />
                                        <FormErrorMessage fontSize={["10px", "16px"]} noOfLines={[1, 2]} maxW={200} alignItems={"start"} >{form.errors.topic}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Box>
                    </Box>
                    <Field name="message" >
                        {({ field, form }: any) => (
                            <FormControl isInvalid={form.errors.message && form.touched.message} {...formControlProps}>
                                <FormLabel
                                    htmlFor="message"
                                    color={"#4365BE"}
                                    fontSize={["xs", "16px"]}
                                >
                                    Mensagem
                                </FormLabel>
                                <Box display={"flex"} alignItems="start" columnGap={"5px"} justifyContent={["right", "center"]}>
                                    <Textarea {...field}  {...inputProps} id="message" className="input-field" resize={"none"}>
                                    </Textarea>

                                </Box>
                                {/* <FormErrorMessage fontSize={["10px", "16px"]} noOfLines={[1, 2]} maxW={200} alignItems={"start"} >{form.errors.message}</FormErrorMessage> */}
                            </FormControl>
                        )}
                    </Field>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Button
                            id="elementor"
                            color="gray.500"
                            size="lg"
                            width={"50%"}
                            outline={"none"}
                            border={"none"}
                            bgColor="yellow.400"
                            isLoading={props.isSubmitting}
                            type="submit"
                            borderRadius={["10","50"]}
                            disabled={props.isSubmitting || !props.isValid}
                            justifySelf={"center"}
                            mb={["5%","5%","5%","5%","5%","0%"]}
                        >
                            <Text fontSize={["small", "sm", "md"]} color={"#022c43"} >Enviar</Text>
                        </Button>
                    </Box>

                </Form>
            </Box>
        )}
    </Formik>;
};
export default ContactForm;