import { Box,  } from "@chakra-ui/react";
// import { Global } from "@emotion/react";
import React, {  } from "react";
// import { useLocation } from "react-router-dom";
import { DarkWrapper } from "./styled";
// import Footer from "../../pages/Sign/Footer";
// import { Header } from "./Header";
interface Props {
    children: React.ReactNode;
  }

const DarkLayout: React.FC<Props> = ({ children }) => {


    return <>
        <DarkWrapper>
            <Box w={["100%"]} m="auto" justifyContent="center" alignItems={"center"} display={"flex"} >
                <Box w={"100%"} >
                    {children}
                </Box>
            </Box>
        </DarkWrapper>
    </>
};

export default DarkLayout;
