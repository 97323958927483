import {
  Box,
  //   IconButton,
  useBreakpointValue,
  Stack,
  Button,
  Flex,
  Image,
  Text,
  Heading,
  Container,
  Link,
  Circle,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import { DarkLayout } from "../../layout/darkLayout";
import Header from "./header/navbar";
import Slider from "react-slick";

// import { Carousel, LeftButton, Provider, RightButton } from "chakra-ui-carousel";

// import { GrFacebookOption, GrLinkedinOption, GrTwitter } from "react-icons/gr";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

// import Spline from '@splinetool/react-spline';
import "../homePage/HomePage.scss";

import "@fontsource/goldman";
import "@fontsource/jetbrains-mono";
import "@fontsource/be-vietnam-pro";

import { motion } from "framer-motion";
import ContactForm from "./contactForm/contactFrom";

// import justiceImg from "../../assets/imgs/justice.jpeg"
// import lawyersPhoto from "../../assets/imgs/lawyersBgW.jpeg";
import ourTeam from "../../assets/imgs/ourTeam.jpeg";

import client1 from "../../assets/imgs/client1.jpeg";
import client2 from "../../assets/imgs/client2.jpeg";
import client3 from "../../assets/imgs/client3.jpeg";

import { FaMap, FaPhone, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
};

const HomePage: React.FC = () => {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState<Slider | null>(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "40px" });

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    // {
    //     title: 'DIREITO PREVIDENCIÁRIO',
    //     text: "Somos especialistas em concessão, revisão e planejamento de benefícios previdenciários do INSS.",
    //     image: { justiceImg },
    //     contact: "Entre em Contasdasdas"
    // },
    {
      title: "DIREITO PREVIDENCIÁRIO:",
      text: "Escritório especializado em concessão e revisão de aposentadorias, planejamento previdenciário e concessão de benefícios do INSS.",
      image:
        "https://www.freepik.com/free-photo/male-female-business-people-working-tablet-office_8828156.htm#page=2&query=lawyer&position=3&from_view=keyword&track=sph&uuid=eb427a83-1bea-498f-b2bf-1d9d73d63f6f",
      contact: "Entre em Contato",
    },
  ];
  return (
    <>
      <DarkLayout>
        <Box w={"100%"}>
          <Header />

          <Box>
            <Box
              position={"relative"}
              height={"auto"}
              width={"full"}
              overflow={"hidden"}
            >
              <Box
                justifyContent={"center"}
                height={"100%"}
                alignContent={"center"}
                alignItems={"center"}
                display={"flex"}
              >
                <Box
                  position={"relative"}
                  height={"100%"}
                  width={"full"}
                  overflow={"hidden"}
                >
                  {/* CSS files for react-slick */}
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                  />
                  {/* Left Icon */}

                  <IconButton
                    display={"none"}
                    as={motion.button}
                    whileHover={{
                      scale: 1.1,
                      opacity: 1,
                    }}
                    opacity={"0.1"} //add opacity on hover
                    aria-label="left-arrow"
                    variant="ghost"
                    position="absolute"
                    left={side}
                    top={top}
                    // transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}
                  >
                    <BiLeftArrowAlt size="40px" />
                  </IconButton>
                  {/* Right Icon */}
                  <IconButton
                    display={"none"}
                    as={motion.button}
                    whileHover={{
                      scale: 1.1,
                      opacity: 1,
                    }}
                    opacity={"0.1"} //add opacity on hover
                    aria-label="right-arrow"
                    variant="ghost"
                    position="absolute"
                    right={side}
                    top={top}
                    // transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}
                  >
                    <BiRightArrowAlt size="40px" />
                  </IconButton>
                  <Link href="https://wa.me/5541998736363" isExternal>
                    <Button
                      h={"7vh"}
                      w={"7vh"}
                      borderRadius={"50%"}
                      position={"fixed"}
                      bg={"green"}
                      left={["80%", "94%"]}
                      top={"85%"}
                      zIndex={"999"}
                    >
                      <Circle>
                        <FaWhatsapp color="white" size={30} />
                      </Circle>
                    </Button>
                  </Link>

                  {/* Slider */}
                  <Slider {...settings} ref={(slider) => setSlider(slider)}>
                    {cards.map((card, index) => (
                      <Box
                        key={index}
                        height={["500px", "700px"]}
                        position="relative"
                        backgroundPosition="top"
                        backgroundRepeat="no-repeat"
                        backgroundSize={["cover", "100%"]}
                        // make it smaller and maybe bg more darker
                        // backgroundImage={`(${card.image})`}
                        backgroundImage={
                          "linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url('justice.jpeg')"
                        }
                      >
                        {/* This is the block you need to change, to customize the caption */}
                        <Container
                          size="container.lg"
                          height={["400px", "600px"]}
                          position="relative"
                          opacity={"1"}
                          display={"flex"}
                          justifyItems={"center"}
                        >
                          <Box display={"flex"} justifyContent={"center"}>
                            <Stack
                              spacing={6}
                              w={"100%"}
                              maxW={"lg"}
                              position="absolute"
                              top="50%"
                              left={["0%", "9%"]}
                              transform="translate(0, -50%)"
                            >
                              <motion.div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                whileHover={{ scale: 1.1 }}
                                transition={{ duration: 0.5 }}
                              >
                                <Heading
                                  textAlign={"center"}
                                  fontSize={{
                                    base: "2xl",
                                    md: "4xl",
                                    lg: "6xl",
                                  }}
                                >
                                  {card.title}
                                </Heading>
                              </motion.div>

                              <Text
                                fontSize={{ base: "md", lg: "xl" }}
                                textAlign={"center"}
                                w={"100%"}
                                color="lightgray"
                              >
                                {card.text}
                              </Text>
                              <Link
                                ml={["auto"]}
                                mr={["auto"]}
                                href="https://wa.me/5541998736363"
                                isExternal
                                w={"50%"}
                              >
                                <Button
                                  _hover={{
                                    bg: "#40798C",
                                  }}
                                  w={"100%"}
                                >
                                  {card.contact}
                                </Button>
                              </Link>
                            </Stack>
                          </Box>
                        </Container>
                      </Box>
                    ))}
                  </Slider>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box className="sectionBg blackSquares" height={"75vh"}>
                        <Box display={"flex"} w={"100%"} h={"100%"}>
                            <Box w={"60%"} >
                                <Box
                                    as={motion.div}
                                    initial={{ opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                    }}
                                    viewport={{ once: true }}
                                    whileHover={{ scale: 1.02 }}
                                    transition='1s'  //turn this box into a motion.div and tune with the speed that is appearign same for the other box
                                    className="whiteCard shadowWhiteCard"
                                    h={"100%"} w={"70%"}
                                >

                                </Box>

                            </Box>
                            <Box w={"40%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Text color={"black"} fontWeight={"semibold"} mt={"5px"} bg={"white"} >
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    Maxime facilis laboriosam eum laborum natus ex totam vel delectus
                                    itaque assumenda provident quod numquam quis ad quidem, sed illo recusandae nihil.
                                </Text>
                            </Box>
                        </Box>
                    </Box> */}
          <Box
            className="sectionBg "
            backgroundSize={["cover", "100%"]}
            backgroundImage={
              "linear-gradient(rgba(0, 0, 0, 0.927),rgba(0, 0, 0, 0.5)), url('NewAboutus.jpeg')"
            }
            backgroundRepeat={"no-repeat"}
            backgroundPosition="center"
            mt={"10vh"}
          >
            <Box display={"flex"} w={"80%"} h={"100%"}>
              <Box
                w={"100%"}
                display={"flex"}
                flexDirection={"column"}
                bg={"transparent"}
              >
                <Box w={"100%"} display={"flex"} textAlign={"left"}>
                  <Text fontSize="5xl" bg={""} id="aboutUs">
                    Sobre nós
                  </Text>
                </Box>
                <Box mt={"1%"} mr={["0%", "10%"]}>
                  <Text
                    fontSize={["md", "lg"]}
                    color={"white"}
                    textAlign={"justify"}
                    fontWeight={"semibold"}
                    w={"100%"}
                    mt={"5px"}
                    pr={"2%"}
                  >
                    O escritório Razzines & Avila teve seu início marcado pela
                    visão empreendedora de sua sócia e administradora, Marta
                    Maria Razzines, em 2016. Inicialmente focado no Direito
                    Civil, o escritório expandiu seus horizontes em 2022, ao
                    ingressar nas áreas do Direito Previdenciário e Sucessório,
                    alcançando resultados notáveis em tempo recorde. A chegada
                    da experiente advogada Giselle Avila no mesmo ano fortaleceu
                    ainda mais a atuação em Ações Previdenciárias, refletindo o
                    compromisso incansável em assegurar o acesso à justiça para
                    todos, independentemente de sua condição socioeconômica. O
                    escritório se consolida sobre os alicerces da seriedade,
                    dedicação, atendimento humanizado e eficiência. Valorizamos
                    a transparência, ética e respeito em todas as nossas
                    interações, assegurando que você se sinta ouvido(a) e
                    apoiado(a) em todo o processo.
                  </Text>
                  <Link href="https://wa.me/5541998736363" isExternal>
                    <Button colorScheme="teal" size="lg" mt={"5%"}>
                      Saiba mais
                    </Button>
                  </Link>
                </Box>
              </Box>
              {/* <Box w={"40%"} >
                                <Box
                                    as={motion.div}
                                    initial={{ opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                    }}
                                    viewport={{ once: true }}
                                    whileHover={{ scale: 1.02 }}
                                    transition='1s '
                                    className=" shadowWhiteCard" //maybe without blackCard
                                    h={"100%"}
                                    w={"100%"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                >
                                    <Box display={"flex"} justifyContent={"center"} justifyItems={"center"}>
                                        <Image src={lawyersPhoto} objectFit='cover'
                                        />
                                    </Box>
                                </Box>

                            </Box> */}
            </Box>
          </Box>
          {/* sobren nos maior */}
          {/* <Box className="sectionBg " bgColor={"#999393"} height={"80vh"} display={"flex"} alignItems={"center"} alignContent={"center"} justifyItems={"center"} justifyContent={"center"} >
                        <Box display={"flex"} w={"80%"} h={"100%"}>
                            <Box w={"100%"} display={"flex"} flexDirection={"column"} bg={"transparent"} >
                                <Box w={"100%"} display={"flex"} textAlign={"left"}>
                                    <Text fontSize='5xl' bg={""}>Sobre nós</Text>
                                </Box>
                                <Box mt={"1%"} mr={"10%"}>
                                    <Text fontSize='lg' color={"white"} fontWeight={"semibold"} w={"100%"} mt={"5px"}>
                                        O escritório Razzines & Avila iniciou sua história com a sócia/administradora Marta Maria Razzines no ano 2016. Inicialmente optou por concentrar seu estudo e trabalho com Direito de Família e Sucessões, passando a expandir o seu estudo para Direito Previdenciário obtendo resultados notáveis. Desde 2022 junto com a sócia Giselle Machado de Avila passou a se dedicar principalmente em Ações Previdenciárias trabalhando incansavelmente para garantir que a justiça seja acessível a todos, independentemente da condição socioeconômica.
                                        O escritório se consolidou a partir dos pilares da seriedade, dedicação, atendimento humanizado e eficiência.
                                        Valorizamos a transparência, a ética e o respeito em todas as nossas interações, garantindo que você se sinta ouvido(a) e apoiado(a) durante todo o processo.
                                    </Text>
                                    <Button colorScheme='teal' size='lg' mt={"5%"}>
                                        Saiba mais
                                    </Button>
                                </Box>
                            </Box>
                            <Box w={"40%"} >
                                <Box
                                    as={motion.div}
                                    initial={{ opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                    }}
                                    viewport={{ once: true }}
                                    whileHover={{ scale: 1.02 }}
                                    transition='1s '
                                    className=" shadowWhiteCard" //maybe without blackCard
                                    h={"100%"}
                                    w={"100%"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                >
                                    <Box display={"flex"} justifyContent={"center"} justifyItems={"center"}>
                                        <Image src={lawyersPhoto} objectFit='cover'
                                        />
                                    </Box>
                                </Box>

                            </Box>
                        </Box>

                    </Box> */}
          {/* sobre nos versão escura */}
          {/* <Box className="sectionBg " bgColor={"black.100"} height={"60vh"} >
                        <Box display={"flex"} w={"80%"} h={"100%"}>
                            <Box w={"100%"} display={"flex"} flexDirection={"column"} bg={"transparent"} >
                                <Box w={"100%"} display={"flex"} textAlign={"left"}>
                                    <Text fontSize='5xl' bg={""}>Sobre nós</Text>
                                </Box>
                                <Box mt={"1%"} mr={"10%"}>
                                    <Text fontSize='lg' color={"white"} fontWeight={"semibold"} w={"100%"} mt={"5px"}>
                                        O escritório Razzines & Avila iniciou sua história com a sócia/administradora Marta Maria Razzines no ano 2016. Inicialmente optou por concentrar seu estudo e trabalho com Direito de Família e Sucessões, passando a expandir o seu estudo para Direito Previdenciário obtendo resultados notáveis. Desde 2022 junto com a sócia Giselle Machado de Avila passou a se dedicar principalmente em Ações Previdenciárias trabalhando incansavelmente para garantir que a justiça seja acessível a todos, independentemente da condição socioeconômica.
                                        O escritório se consolidou a partir dos pilares da seriedade, dedicação, atendimento humanizado e eficiência.
                                        Valorizamos a transparência, a ética e o respeito em todas as nossas interações, garantindo que você se sinta ouvido(a) e apoiado(a) durante todo o processo.
                                    </Text>
                                    <Button colorScheme='teal' size='lg' mt={"5%"}>
                                        Saiba mais
                                    </Button>
                                </Box>
                            </Box>
                            <Box w={"40%"} >
                                <Box
                                    as={motion.div}
                                    initial={{ opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                    }}
                                    viewport={{ once: true }}
                                    whileHover={{ scale: 1.02 }}
                                    transition='1s '
                                    className=" shadowWhiteCard" //maybe without blackCard
                                    h={"100%"}
                                    w={"100%"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                >
                                    <Box display={"flex"} justifyContent={"center"} justifyItems={"center"}>
                                        <Image src={lawyersPhoto} objectFit='cover'
                                        />
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box> */}

          {/* serviços */}
          <Box
            id="services"
            className="sectionBg "
            bgColor={"black.100"}
            height={"auto"}
            display={"flex"}
            alignItems={"center"}
            alignContent={"center"}
            justifyItems={"center"}
            justifyContent={"center"}
          >
            <Box display={"flex"} textAlign={["center", "left"]}>
              <Text fontSize="5xl" bg={""} mb={"10%"}>
                Áreas de atuação
              </Text>
            </Box>
            <Box
              w={"100%"}
              h={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              backgroundSize={["cover", "100%"]}
              backgroundImage={
                "linear-gradient(rgba(0, 0, 0, 0.927),rgba(0, 0, 0, 0.5)), url('lawyersserv.jpeg')"
              }
              backgroundRepeat={"no-repeat"}
              alignItems={"center"}
              alignContent={"center"}
              justifyItems={"center"}
            >
              <Box
                as={motion.div}
                initial={{ opacity: 0 }}
                whileInView={{
                  opacity: 1,
                }}
                viewport={{ once: true }}
                whileHover={{ scale: 1.02 }}
                transition="1s "
                className=" shadowWhiteCard" //maybe without blackCard
                h={"80%"}
                w={"82%"}
                display={"flex"}
                padding={"3%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <Text fontSize={["lg", "4xl"]} textAlign={"center"}>
                    Nossa equipe está pronta para fornecer serviços jurídicos
                    excepcionais tanto no âmbito do Direito Público quanto no
                    Privado, aliando um profundo entendimento das questões
                    sociais que permeiam nosso sistema jurídico.
                    {/* "Nossa equipe está preparada para oferecer serviços jurídicos excepcionais nas esferas de <Text as='u'>Direito Público e Privado</Text> ,combinado
                                        com profundo entendimento das questões sociais que envolvem nosso sistema jurídico.
                                        O escritório possui grande experiência em Direito das Sucessões e Direito Previdenciário em que trabalhamos incansavelmente para garantir que a justiça seja acessível a todos, independentemente da condição socioeconômica.
                                        Buscamos garantir o seu direito de receber qualquer auxílio do <Text as='u'>INSS</Text> ( <Text as='u'>doença</Text>, <Text as='u'>acidente</Text>, <Text as='u'>reclusão</Text>), <Text as='u'>pensão por morte</Text>, <Text as='u'>salário maternidade</Text>, <Text as='u'>benefício assistencial</Text> (BPC/LOAS)
                                        e <Text as='u'> aposentadorias</Text>.
                                        Com abordagem centrada no cliente, buscamos entender profundamente as necessidades e objetivos de cada pessoa que atendemos." */}
                  </Text>
                </Box>
              </Box>
              <Box w={"100%"} h={"auto"} display={"flex"}>
                <Box w={"100%"}>
                  <Accordion
                    allowMultiple
                    allowToggle
                    backgroundColor={"black.800"}
                    mt={"2%"}
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box
                            as="span"
                            flex="1"
                            fontSize={["md", "3xl"]}
                            textAlign="center"
                            textColor={"black.800"}
                          >
                            DIREITO SUCESSÓRIO:
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        textAlign={"justify"}
                        pb={4}
                        fontSize={["sm", "2xl"]}
                      >
                        Nossa experiência e dedicação contínuas no campo do
                        Direito Sucessório nos capacitam a orientar tanto em
                        inventários extrajudiciais quanto judicializados,
                        identificando o melhor curso de ação para cada caso
                        específico, em conformidade com a legislação e a
                        jurisprudência vigentes. Priorizamos a promoção da
                        conciliação entre os herdeiros, buscando administrar o
                        processo preferencialmente de forma extrajudicial,
                        quando permitido pela lei, por meio do Cartório, visando
                        economia e celeridade.
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
                <Box w={"100%"} h={"100%"}>
                  <Accordion
                    allowMultiple
                    allowToggle
                    backgroundColor={"black.800"}
                    mt={"2%"}
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box
                            flex={"1"}
                            fontSize={["md", "3xl"]}
                            textAlign="center"
                            textColor={"black.800"}
                          >
                            DIREITO PREVIDENCIÁRIO:
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pb={4}
                        fontSize={["sm", "2xl"]}
                        textAlign={"justify"}
                      >
                        Nossa expertise em Direito Previdenciário, especialmente
                        após as mudanças trazidas pela Reforma da Previdência, é
                        aplicada com rigor para prover uma análise minuciosa e
                        garantir os direitos dos segurados, independentemente de
                        sua condição socioeconômica. Buscamos elucidar os
                        direitos junto ao INSS por meio de consultorias e
                        planejamentos previdenciários, visando assegurar a
                        melhor aposentadoria possível, seja ela especial, rural,
                        híbrida, por idade ou tempo de contribuição. Além disso,
                        trabalhamos para garantir os direitos aos auxílios
                        oferecidos pelo INSS, como auxílio-doença,
                        auxílio-acidente/acidentário, auxílio-reclusão, pensão
                        por morte, salário-maternidade e benefício assistencial
                        BPC/LOAS.
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Box>

              {/* change height too 90vh and make text bigger */}
            </Box>
          </Box>

          {/* nossa equipe */}
          <Box
            className="sectionBg "
            id="team"
            bgColor={"#999393"}
            height={["auto", "90vh", "90vh", "90vh", "100vh", "80vh"]}
            display={"flex"}
            alignItems={"center"}
            alignContent={"center"}
            justifyItems={"center"}
            justifyContent={"center"}
          >
            <Box>
              <Text fontSize="5xl" bg={""} mb={"10%"}>
                Nossa Equipe
              </Text>
            </Box>
            <Box
              display={"flex"}
              flexDir={["column", "row"]}
              w={"80%"}
              h={"100%"}
            >
              <Box
                w={"55%"}
                h={"100%"}
                mr={"5%"}
                display={["none", "flex"]}
                flexDir={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                bg={"transparent"}
              >
                {/* DESKTOP OUR TEAM */}
                <Box
                  h={"auto"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Text
                    fontSize={["md", "2xl", "xl", "2xl", "2xl", "3xl"]}
                    textAlign={"center"}
                  >
                    GISELLE M. DE AVILA
                    <Text>Advogada, inscrita na OAB/PR sob o número</Text>
                  </Text>
                  <Text
                    fontSize={["md", "2xl", "xl", "2xl", "2xl", "3xl"]}
                    textAlign={"center"}
                  >
                    107.118{" "}
                  </Text>
                </Box>
                <Box h={"60%"}>
                  <Text
                    fontSize={["sm", "xl", "sm", "lg", "lg", "xl"]}
                    textAlign={"justify"}
                  >
                    Com vasta experiência em Direito Civil, Direito das Famílias
                    e Direito Criminal, concentra sua atuação principalmente no
                    campo do Direito Previdenciário. Sua expertise é
                    complementada por uma Especialização em Direito Público,
                    obtida na FEMPAR - Fundação Escola do Ministério Público do
                    Paraná.{" "}
                  </Text>
                </Box>
              </Box>

              <Box w={"100%"}>
                <Box
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  whileInView={{
                    opacity: 1,
                  }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.02 }}
                  transition="1s "
                  className=" shadowWhiteCard" //maybe without blackCard
                  display={"flex"}
                  justifyContent={"center"}
                  h={["100%","100%","100%","100%","100%","auto"]}
                >
                  <Box
                    display={"flex"}
                    boxSize={"auto"}
                    justifyContent={"center"}
                    justifyItems={"center"}
                    alignItems={"center"}
                    alignContent={"center"}
                    h={"100%"}
                  >
                    <Image src={ourTeam} objectFit="cover" />
                  </Box>
                </Box>
              </Box>
              <Box
                w={"55%"}
                h={"100%"}
                ml={"5%"}
                display={["none", "flex"]}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
              >
                <Box
                  h={"auto"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Text
                    fontSize={["md", "2xl", "xl", "2xl", "2xl", "3xl"]}
                    textAlign={"center"}
                  >
                    MARTA M. RAZZINES Advogada inscrita na OAB/PR sob o número
                  </Text>
                  <Text fontSize={["md", "2xl", "xl", "2xl", "2xl", "3xl"]} textAlign={"center"}>
                    83.311
                  </Text>
                </Box>
                <Box h={"60%"}>
                  <Text
                    fontSize={["sm", "xl", "sm", "lg", "lg", "xl"]}
                    textAlign={"justify"}
                  >
                    Com vasta experiência na área cível, direito de família e
                    sucessões, ela concentra atualmente seus esforços e
                    conhecimentos no direito Previdenciário. Sua especialização
                    nesse campo foi obtida na ESMAFE - Escola de Magistratura
                    Federal, destacando-se pelo domínio do direito
                    Previdenciário{" "}
                  </Text>
                </Box>
              </Box>

{/* todo: make table into the mobile text version */}


              {/* MOBILE TEXT */}
              <Box
                display={["flex", "none"]}
                justifyContent={"center"}
                mt={["5%", "0%"]}
              >
                <Box w={"100%"}>
                  <Text fontSize={["md", "3xl"]} textAlign={"center"}>
                    GISELLE M. DE AVILA
                    <Text>Advogada, inscrita na OAB/PR sob o número</Text>
                  </Text>
                  <Text fontSize={["md", "3xl"]} textAlign={"center"}>
                    107.118{" "}
                  </Text>
                  <Text fontSize={["sm", "xl"]} textAlign={"center"}>
                    Com vasta experiência em Direito Civil, Direito das Famílias
                    e Direito Criminal, concentra sua atuação principalmente no
                    campo do Direito Previdenciário. Sua expertise é
                    complementada por uma Especialização em Direito Público,
                    obtida na FEMPAR - Fundação Escola do Ministério Público do
                    Paraná.{" "}
                  </Text>
                </Box>
                <Box w={"100%"} pl={["20px", "0px"]}>
                  <Text fontSize={["md", "3xl"]} textAlign={"center"}>
                    MARTA M. RAZZINES Advogada inscrita na OAB/PR sob o número
                  </Text>
                  <Text fontSize={["md", "3xl"]} textAlign={"center"}>
                    83.311
                  </Text>
                  <Text fontSize={["sm", "xl"]} textAlign={"center"}>
                    Com vasta experiência na área cível, direito de família e
                    sucessões, ela concentra atualmente seus esforços e
                    conhecimentos no direito Previdenciário. Sua especialização
                    nesse campo foi obtida na ESMAFE - Escola de Magistratura
                    Federal, destacando-se pelo domínio do direito
                    Previdenciário{" "}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* OUR CLIENTS */}
          <Box
            id=""
            className="sectionBg "
            bgColor={"black.100"}
            height={"auto"}
            display={"flex"}
            alignItems={"center"}
            alignContent={"center"}
            justifyItems={"center"}
            justifyContent={"center"}
          >
            <Box>
              <Text fontSize={["xl", "5xl"]} bg={""} mb={"10%"}>
                NOSSOS CLIENTES
              </Text>
            </Box>
            <Box
              w={"80%"}
              h={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              justifyItems={"center"}
            >
              {/* desktop */}
              <Box display={["none", "inline"]}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={"100%"}
                    h={"100%"}
                  >
                    <Image boxSize="70%" src={client1}></Image>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={"100%"}
                    h={"100%"}
                  >
                    <Image boxSize="73%" mt={"auto"} src={client2}></Image>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={"100%"}
                    h={"100%"}
                  >
                    <Image boxSize="79%" src={client3}></Image>
                  </Box>
                </Box>
                <Box display={"flex"} textColor={"white"} w={"100%"} mt={"2%"}>
                  <Box
                    w={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Text fontSize={"2xl"}>Tatiana Magali Gonçalves</Text>
                    <Text pl={"10%"} pr={"10%"} mt={"5%"} textAlign={"justify"}>
                      "O comportamento profissional e dedicação ao meu caso não
                      apenas me proporcionou segurança, mas também superou
                      minhas expectativas. Agradeço profundamente o trabalho
                      árduo realizado. Terei prazer em recomendar os serviços a
                      outras pessoas que possam precisar de assistência
                      jurídica."
                    </Text>
                  </Box>
                  <Box
                    w={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Text fontSize={"2xl"}>Elis Fauth Nadal</Text>
                    <Text pl={"10%"} pr={"10%"} mt={"5%"} textAlign={"justify"}>
                      "Reconhecer em palavras o trabalho que a Dra. Marta
                      desempenhou é muito pouco, mas gratidão sim é um jeito
                      singelo de expressar"
                    </Text>
                  </Box>
                  <Box
                    w={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDir={"column"}
                  >
                    <Text fontSize={"2xl"}>Luis Carlos Micaloski</Text>
                    <Text pl={"10%"} pr={"10%"} mt={"5%"} textAlign={"justify"}>
                      "Sou cliente do escritório e desde o início tive uma boa
                      impressão do profissionalismo e domínio sobre os assuntos.
                      Estou super satisfeito com o resultado e capricho, pois
                      sempre entram em contato comigo passando todos os detalhes
                      do processo. Minha avaliação é resumida em duas palavras:
                      satisfação e qualidade. A confiança é conquistada e por
                      isso já indiquei outras pessoas."
                    </Text>
                  </Box>
                </Box>
              </Box>

              {/* clients mobile */}

              <Box display={["flex", "none"]}>
                <Box
                  display={"flex"}
                  flexDir={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  w={"100%"}
                  h={"100%"}
                >
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    alignItems={"center"}
                    alignContent={"center"}
                    justifyContent={"center"}
                  >
                    <Image boxSize="70%" src={client1}></Image>
                    <Box mt={"10%"}>
                      <Text fontSize={"2xl"} textAlign={"center"}>
                        Tatiana Magali Gonçalves
                      </Text>
                      <Text
                        pl={"10%"}
                        pr={"10%"}
                        mt={"5%"}
                        textAlign={"center"}
                      >
                        "O comportamento profissional e dedicação ao meu caso
                        não apenas me proporcionou segurança, mas também superou
                        minhas expectativas. Agradeço profundamente o trabalho
                        árduo realizado. Terei prazer em recomendar os serviços
                        a outras pessoas que possam precisar de assistência
                        jurídica."
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    alignItems={"center"}
                    alignContent={"center"}
                    justifyContent={"center"}
                    mt={"10%"}
                  >
                    <Image boxSize="70%" src={client2}></Image>
                    <Box
                      mt={"10%"}
                      display={"flex"}
                      flexDir={"column"}
                      alignItems={"center"}
                    >
                      <Text fontSize={"2xl"}>Elis Fauth Nadal</Text>
                      <Text
                        pl={"10%"}
                        pr={"10%"}
                        mt={"5%"}
                        textAlign={"center"}
                      >
                        "Reconhecer em palavras o trabalho que a Dra. Marta
                        desempenhou é muito pouco, mas gratidão sim é um jeito
                        singelo de expressar"
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    alignItems={"center"}
                    alignContent={"center"}
                    justifyContent={"center"}
                    mt={"10%"}
                  >
                    <Image boxSize="70%" src={client3}></Image>
                    <Box
                      mt={"10%"}
                      display={"flex"}
                      flexDir={"column"}
                      alignItems={"center"}
                    >
                      <Text fontSize={"2xl"}>Luis Carlos Micaloski</Text>
                      <Text
                        pl={"10%"}
                        pr={"10%"}
                        mt={"5%"}
                        textAlign={"center"}
                      >
                        "Sou cliente do escritório e desde o início tive uma boa
                        impressão do profissionalismo e domínio sobre os
                        assuntos. Estou super satisfeito com o resultado e
                        capricho, pois sempre entram em contato comigo passando
                        todos os detalhes do processo. Minha avaliação é
                        resumida em duas palavras: satisfação e qualidade. A
                        confiança é conquistada e por isso já indiquei outras
                        pessoas."
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* change height too 90vh and make text bigger */}
            </Box>
          </Box>

          {/* entre em contato */}
          <Box className="sectionBg" bg={"black.900"} height={["auto", "80vh"]}>
            <Box display={"flex"} w={"100%"} h={"100%"}>
              <Box
                w={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Flex minHeight={"65vh"} justify={"center"} mt={"5vh"} pb={"5vh"}>
                  <Box
                    borderRadius={"15px"}
                    bg={"white "}
                    w={["85vw", "65vw"]}
                    justifyContent={"center"}
                    textAlign={"center"}
                  >
                    <Text
                      fontSize={["3xl", "5xl"]}
                      mt={"30px"}
                      color={"#4365BE"}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      Entre em Contato
                    </Text>
                    <ContactForm />
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>

          {/* footer */}
          <Flex
            height={["auto","40vh","45vh","40vh","45vh", "35vh"]}
            maxH={["auto", "50vh"]}
            bg={"#0d1f2d"}
            justify={"center"}
          >
            <Box
              w={"100%"}
              height={"100%"}
              display={"flex"}
              flexDir={["column", "row"]}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                w={"50%"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                textAlign={"left"}
                alignItems={"center"}
                mt={["5%", "0% "]}
                pl={["5%","5%","5%","5%","0%","0%"]}
              >
                <Box>
                  <Text
                    color={"white"}
                    alignSelf={"left"}
                    fontSize={["xl", "3xl"]}
                    fontWeight={"bold"}
                  >
                    Entre em contato:
                  </Text>
                  <Box mt={"2%"} display={"flex"}>
                    <Box mr={"3%"} display={"flex"} alignItems="center">
                      <FaMap size={20} />
                    </Box>
                    <Text fontSize={["lg", "2xl"]}>Endereço:</Text>
                  </Box>
                  <Text>
                    Rua Jaime Rodrigues da Rocha, 103, sala 42 – Capão Raso –
                    Curitiba/PR
                  </Text>
                  <Box mt={"2%"} display={"flex"}>
                    <Box mr={"3%"} display={"flex"} alignItems={"center"}>
                      <FaPhone size={20} />
                    </Box>
                    <Text fontSize={"2xl"} as="em">
                      Números:
                    </Text>
                  </Box>
                  <Text>41 998736363 (Giselle)</Text>
                  <Text>41 997673134 (Marta)</Text>

                  <Box mt={"2%"} display={"flex"}>
                    <Box mr={"3%"} display={"flex"} alignItems={"center"}>
                      <MdEmail size={20} />
                    </Box>
                    <Text fontSize={"2xl"} as="em">
                      Email:
                    </Text>
                  </Box>
                  <Text>razzineseavila.adv@gmail.com</Text>
                </Box>
              </Box>
              <Box
                w={"50%"}
                h={"65%"}
                display={["none", "flex"]}
                justifyContent={"left"}
                alignItems={"center"}
              >
                <iframe
                  height={"100%"}
                  width={"90%"}
                  title="teste"
                  allowFullScreen
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3600.6646490160474!2d-49.297373123824855!3d-25.516228436310463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcfcbd5039bc29%3A0xb92ce72a6c51a125!2sR.%20Jaime%20Rodrigues%20da%20Rocha%2C%20103%20-%20Cap%C3%A3o%20Raso%2C%20Curitiba%20-%20PR%2C%2081150-130!5e0!3m2!1spt-BR!2sbr!4v1712226398502!5m2!1spt-BR!2sbr"
                  loading="lazy"
                />
              </Box>
              <Box
                w={"80%"}
                h={"65%"}
                display={["flex", "none"]}
                justifyContent={"center"}
                alignItems={"center"}
                mt={"10%"}
                mb={"10%"}
              >
                <iframe
                  height={"100%"}
                  width={"90%"}
                  title="teste"
                  allowFullScreen
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3600.6646490160474!2d-49.297373123824855!3d-25.516228436310463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcfcbd5039bc29%3A0xb92ce72a6c51a125!2sR.%20Jaime%20Rodrigues%20da%20Rocha%2C%20103%20-%20Cap%C3%A3o%20Raso%2C%20Curitiba%20-%20PR%2C%2081150-130!5e0!3m2!1spt-BR!2sbr!4v1712226398502!5m2!1spt-BR!2sbr"
                  loading="lazy"
                />
              </Box>
            </Box>
          </Flex>
          {/* <Flex height={"65vh"} justify={"center"} mt={"10vh"}>
                        <Box borderRadius={"15px"} bg={"white "} w={"65vw"} justifyContent={"center"} textAlign={"center"}>
                            <Text fontSize={"5xl"} mt={"30px"} color={"#4365BE"} display={"flex"} justifyContent={"center"}>
                                Entre em Contato
                            </Text>
                            <ContactForm />
                        </Box>
                    </Flex> */}
        </Box>
      </DarkLayout>
    </>
  );
};
export default HomePage;

// linear-gradient(to right, rgb(219, 234, 254), rgb(147, 197, 253), rgb(59, 130, 246))

// add a captcha ?

// >Image by senivpetro</a> on Freepik colocar no footer
